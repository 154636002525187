<template>
  <v-col class="mt-2">
    <v-col class="shades white" :class="$vuetify.breakpoint.mdAndUp ? 'px-10 py-6' : 'px-6 pt-6'">
      <v-row>
        <div class="header">맞춤 제안을 위해 정보를 입력해 주세요.</div>
      </v-row>
      <v-row class="mb-6">
        <v-col cols="12" md="6">
          <div class="label mt-6 mb-2">
            <span class="font-weight-bold text-decoration-underline">체험단 유형</span>을 선택해 주세요.
          </div>
          <v-select
            v-model="form.category.firstName"
            :items="
              Object.entries(option.category || {}).map(([k, v]) => ({
                text: k,
                value: k,
              })) || []
            "
            item-text="text"
            item-value="value"
            height="40px"
            :hide-details="true"
            outlined
            solo
            flat
            @change="checkDone"></v-select>
        </v-col>
      </v-row>
      <v-row class="mb-4" v-if="(premiumPackages?.[0]?.balance || 0) > 0">
        <v-col md="8">
          <div class="label mb-2">
            <span class="font-weight-bold text-decoration-underline">체험단 종류</span>를 선택해주세요.
          </div>
          <v-btn-toggle v-model="form.type" mandatory color="transparent" style="display: flex" @change="checkDone">
            <v-btn
              value="S"
              class="no-hover-effect"
              :style="{
                backgroundColor: 'transparent',
                border: 'none',
                padding: '0px',
                height: '180px',
                margin: '0px 12px 0px 0px',
              }"
              :ripple="false">
              <img :width="$vuetify.breakpoint.mdAndDown ? 136 : 160" height="180" :src="standardStoreImage" />
            </v-btn>
            <v-btn
              value="A"
              class="no-hover-effect"
              :style="{
                backgroundColor: 'transparent',
                border: 'none',
                padding: '0px',
                height: '180px',
                margin: '0px',
              }"
              :ripple="false">
              <img :width="$vuetify.breakpoint.mdAndDown ? 136 : 160" height="180" :src="premiumStoreImage" />
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="label mb-2">
            <span class="font-weight-bold text-decoration-underline">매장 이름</span>을 입력해주세요.
          </div>
          <v-autocomplete
            class="mb-2"
            v-model="form.name"
            :items="naverSearchResult"
            item-text="title"
            item-value="title"
            placeholder="업체명과 지점명을 함께 적어주세요."
            no-data-text="검색 결과가 없습니다."
            :auto-select-first="true"
            @change="selectStore"
            @update:search-input="searchStore">
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle>{{ item.roadAddress }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-skeleton-loader v-if="isFetching" max-height="52" class="pa-4" type="paragraph"></v-skeleton-loader>
              <v-skeleton-loader v-if="isFetching" max-height="52" class="pa-4" type="paragraph"></v-skeleton-loader>
              <v-skeleton-loader v-if="isFetching" max-height="52" class="pa-4" type="paragraph"></v-skeleton-loader>
              <v-list-item
                v-if="!isFetching && directSelectText.length > 0"
                @click="
                  form.name = directSelectText;
                  naverSearchResult = [{ title: directSelectText }];
                ">
                <v-list-item-content>
                  <v-list-item-title>직접 입력: "{{ directSelectText }}"</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="label mb-2">
            블로거에게 제공할 <span class="font-weight-bold text-decoration-underline">최대 할인 금액</span>을 설정해
            주세요.
          </div>
          <v-text-field
            v-model="form.maxPrice"
            name="maxPrice"
            type="number"
            :rules="[errors.first('maxPrice') || !errors.has('maxPrice')]"
            placeholder="최대 할인 금액을 입력해주세요."
            @input="checkDone"
            single-line
            outlined
            solo
            flat
            required>
          </v-text-field>
        </v-col>
      </v-row>

      <div class="question-box py-6 px-4" style="display: flex; flex-direction: column; gap: 20px">
        <div>
          <div class="question-header mb-2">🤔 최대 할인 금액이 뭔가요?</div>
          <div class="label">• 슈퍼멤버스는 블로거의 등급에 따라 차등 할인 혜택이 제공됩니다.</div>
        </div>
        <div>
          <div class="question-header mb-2">{{ `예시) 최대 할인 금액 50,000원 설정 시` }}</div>
          <div>
            <div>• 다이아/블랙: 100% 할인 (최대 50,000원 할인)</div>
            <div>• 레드: 70% 할인 (최대 35,000원 할인)</div>
            <div>• 옐로우: 50% 할인 (최대 25,000원 할인)</div>
          </div>
        </div>
        <div>
          <div class="question-header mb-2">블로거 체험단 등급 기준</div>
          <div>• 다이아 등급 : 상위 0.5% 이내</div>
          <div>• 블랙 등금 : 상위 5% 이내</div>
          <div>• 레드 등급 : 상위 15% 이내</div>
        </div>
      </div>
    </v-col>
  </v-col>
</template>
<script>
import companyMixin from '@/components/shared/companyMixin';
import userMixin from '@/components/shared/userMixin';
import axios from 'axios';

export default {
  name: 'Page1',
  mixins: [userMixin, companyMixin],
  props: ['adsData', 'option'],
  components: {},
  data: function () {
    return {
      form: {
        category: {
          firstName: this.adsData.category.firstName || '',
          first: this.adsData.category.first || '',
          secondName: this.adsData.category.secondName || '',
          second: this.adsData.category.second || '',
          thirdName: this.adsData.category.thirdName || '',
          third: this.adsData.category.third || '',
        },
        name: this.adsData.name || '',
        x: this.adsData.x || '',
        y: this.adsData.y || '',
        searchDebounceTimer: null,
        maxPrice:
          Math.max(
            (this.adsData.naver || {}).blackPrice,
            (this.adsData.naver || {}).redPrice,
            (this.adsData.naver || {}).yellowPrice,
            (this.adsData.naver || {}).diamondPrice
          ) || 0,
        naver: {},
        type: this.adsData.type || 'S',
      },
      directSelectText: '',
      naverSearchResult: this.adsData.name ? [{ title: this.adsData.name }] : [],
      premiumPackages: [],
      isFetching: false,
    };
  },

  async created() {
    let premiumPackages = await this.axios.get(
      `company/user/premium?companyId=${this.user.id}&orderBy=id&direction=desc`
    );
    this.premiumPackages = premiumPackages.data.packages;
  },

  watch: {
    adsData: {
      deep: true,
      handler(newValue) {
        this.form = {
          category: {
            firstName: newValue.category.firstName || '',
            first: newValue.category.first || '',
            secondName: newValue.category.secondName || '',
            second: newValue.category.second || '',
            thirdName: newValue.category.thirdName || '',
            third: newValue.category.third || '',
          },
          name: newValue.name,
          x: newValue.x,
          y: newValue.y,
          type: newValue.type,
          maxPrice:
            (newValue.naver || {}).diamondPrice ||
            (newValue.naver || {}).blackPrice ||
            (newValue.naver || {}).redPrice ||
            (newValue.naver || {}).yellowPrice ||
            0,
          naver: {},
        };
      },
    },

    'adsData.category.firstName': function (value) {
      this.form.category.firstName = value;
      this.form.category.first = this.option.category[value].id;
    },
  },

  methods: {
    async searchStore(searchText) {
      clearTimeout(this.searchDebounceTimer);

      if (searchText.length === 0 || searchText.trim() === '') {
        this.directSelectText = '';
        this.naverSearchResult = [];
        return;
      }

      this.directSelectText = searchText;

      this.searchDebounceTimer = setTimeout(async () => {
        if (this.form.name === searchText) {
          return;
        }

        this.naverSearchResult = [];

        try {
          this.isFetching = true;
          const result = await axios
            .get(`/utils/search/local?q=${searchText}&display=5`, { loading: false })
            .then(res => {
              return res.data;
            })
            .catch(err => {
              console.log(err);
            });

          const formattedResult = result.data.items.map(item => ({
            ...item,
            title: item.title.replace(/<b>/g, '').replace(/<\/b>/g, '').replace(/&amp;/g, '&'),
          }));

          this.naverSearchResult = JSON.parse(JSON.stringify(formattedResult));
        } catch (err) {
          console.log(err);
        } finally {
          this.isFetching = false;
        }
      }, 500);
    },

    async selectStore(title) {
      const target = this.naverSearchResult.find(result => result.title === title);
      this.form.x = target.mapx;
      this.form.y = target.mapy;

      this.checkDone();
    },
    checkDone() {
      this.form['naver'].yellowPrice =
        this.form['naver'].redPrice =
        this.form['naver'].blackPrice =
        this.form['naver'].diamondPrice =
          this.form.maxPrice;

      this.form.step = 1;
      this.$emit('updateEvent', this.form);
    },
  },
  computed: {
    standardStoreImage() {
      const activatedType = this.form.type;
      const isMobile = this.$vuetify.breakpoint.mdAndDown;
      const extension = (isMobile ? '_mobile' : '') + '.png';

      if (activatedType === 'S') {
        return require('@/assets/img/members-type/standard_active' + extension);
      } else {
        return require('@/assets/img/members-type/standard_inactive' + extension);
      }
    },

    premiumStoreImage() {
      const activatedType = this.form.type;
      const isMobile = this.$vuetify.breakpoint.mdAndDown;

      const extension = (isMobile ? '_mobile' : '') + '.png';

      if (activatedType === 'A') {
        return require('@/assets/img/members-type/premium_active' + extension);
      } else {
        return require('@/assets/img/members-type/premium_inactive' + extension);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  font-size: 18px;
  font-weight: 700;
  color: var(--default-2-color);
}

.question-box {
  background-color: var(--disabled-light-color);
}

.question-header {
  font-size: 16px;
  font-weight: 700;
}
</style>
